import React from 'react';
import './with-authorization.scss';

export const WithAuthorization = (WrappedComponent) => {
    return ({ isAuthorized, isDestinationsAuthorized, ...props }) => {
        const className = ['app-permission'];
        if (!isAuthorized) {
            className.push('unauthorized');
        }
        if (isDestinationsAuthorized) {
            className.push('destinations-edit-allowed');
        }

        return (
            <div className={className.join(' ')}>
                <WrappedComponent {...props} />
            </div>
        );
    };
};