import { BaseStore } from './base.store';
import { IStore } from './store.interface';
import { UserData } from '../../dtos/user-data.dto';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import userService from '../../services/user.service';
import { RootStore } from './index';

export class UserStore extends BaseStore implements IStore<UserData> {
    public data: UserData;

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this, {
            data: observable,
            load: action,
            isAuthenticated: computed,
            isEditorRole: computed,
            singleCustomerName: computed
        });
    }

    public async load() {
        try {
            this.setIsLoading(true);
            const res = await userService.getUser();
            runInAction(() => {
                this.data = res;
                this.setIsLoading(false);
            });
        } catch (e) {
            runInAction(() => {
                this.data = null;
                this.setIsLoading(false);
            });
        }
    }

    get isAuthenticated() {
        return !!this.data;
    }

    get isEditorRole() {
        return this.data?.permissions?.some(perm => perm.name === 'settings_edit');
    }

    get isMultiCustomerRole() {
        return this.data?.permissions?.some(perm => perm.name === 'multi_customer');
    }

    get isUnauthorizedDestinationsEditable() {
        return this.data?.permissions?.some(perm => perm.name === 'unauthorized_destinations_settings_edit');
    }

    get singleCustomerName() {
        return this.data.customerName;
    }
}
